.submit-message-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: none;
    z-index: 1111;
    background: #6666;
}

.loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--vp-color-secondary);
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: spin 1s linear infinite;
    margin: auto;
    &.large img {
        width: 15vw;
    }
    &.medium img {
        width: 120px;
    }
    &.small img {
        width: 60px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}